import React, { useEffect, useState } from "react";
import { Todos } from "./Todos";
import firebase from "firebase";

function App() {
  const [user, setUser] = useState<firebase.User | null>(null);
  useEffect(() => {
    firebase.auth().signInAnonymously();
    firebase.auth().onAuthStateChanged(u => setUser(u));
  }, []);
  return user && <Todos user={user} />;
}

export default App;
