import React from "react";
import ReactDOM from "react-dom";
import "./assets/main.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initializeApp, analytics } from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBl0DsTeR1FfLVqX1WObg4wxbKVXUaWlhs",
  authDomain: "fir-b7105.firebaseapp.com",
  databaseURL: "https://fir-b7105.firebaseio.com",
  projectId: "fir-b7105",
  storageBucket: "fir-b7105.appspot.com",
  messagingSenderId: "1092579080131",
  appId: "1:1092579080131:web:b7d64e5af9de25a15def76",
  measurementId: "G-KNHM8QEWE9"
};

initializeApp(firebaseConfig);
analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
