import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/free-regular-svg-icons";

import { useForm } from "react-hook-form";
import { firestore, User } from "firebase";

interface Todo {
  id: number;
  label: string;
  done: boolean;
}

interface TodoInput {
  label: string;
}

export const Todos: React.FC<{ user: User }> = ({ user }) => {
  const { register, handleSubmit, reset } = useForm<TodoInput>();
  const [todos, setTodos] = useState<Todo[]>();
  const doc = firestore()
    .collection("users")
    .doc(user.uid);

  const onSubmit = (data: TodoInput) => {
    const newTodo = {
      id: todos!.length,
      ...data,
      done: false
    };
    setTodos([...todos!, newTodo]);
    reset();
  };

  const toggleDone = (id: number) => {
    const todo = todos!.find(x => x.id === id);
    if (!todo) return;
    todo.done = !todo.done;
    setTodos(todos!.map(x => (x.id === id ? todo : x)));
  };

  useEffect(() => {
    if (todos) {
      doc.set({ todos });
    }
  }, [todos]);

  useEffect(() => {
    doc.get().then(d => setTodos((d.data() as any)?.todos || []));
  }, []);

  if (!todos) {
    return <></>;
  }
  return (
    <div className="container mx-auto mt-8 max-w-lg bg-gray-100 rounded-lg shadow-lg px-4 py-8 min-h-full">
      <div className="text-xl text-center">todos</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          autoFocus
          name="label"
          className="w-full mt-4 outline-none"
          ref={register}
        />
      </form>
      <div className="flex flex-col mt-4">
        {todos.map(todo => (
          <label
            key={todo.id}
            className="text-sm flex flex-row items-center cursor-pointer"
            onClick={() => toggleDone(todo.id)}
          >
            <FontAwesomeIcon
              icon={todo.done ? faCheckSquare : faSquare}
              className="mr-4 "
            />
            <div>{todo.label}</div>
          </label>
        ))}
      </div>
    </div>
  );
};
